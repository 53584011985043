<template>
  <vx-card :title="title">
    <div class="vx-row mb-6" style="width:70%">
      <div class="vx-col sm:w-1/4 w-full flex items-center">
        <span>Sales Area</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <multiselect
          v-model="territoryArea.selected"
          :options="territoryArea.options"
          placeholder="Type to search"
          track-by="name"
          label="name"
          :max-height="125"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-12">
      <div class="core vx-col md:w-1/1 w-full mb-base">
        <vs-table
          search
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">{{this.table.start}} - {{this.table.end}} of {{this.table.total}}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{item}}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
            <div class="btn-group ml-5">
              <vs-button
                size="small"
                color="success"
                type="border"
                @click="handleExport('excel')"
              >Excel</vs-button>
              <vs-button size="small" color="primary" type="border" @click="handleExport('pdf')">PDF</vs-button>
            </div>
          </template>
          <template slot="thead">
            <vs-th sort-key="sku_code">Sku Code</vs-th>
            <vs-th sort-key="item_name">Item Name</vs-th>
            <vs-th sort-key>Warehouse</vs-th>
            <vs-th sort-key>UOM</vs-th>
            <vs-th sort-key>On Hand</vs-th>
            <vs-th sort-key>Reserved</vs-th>
            <vs-th sort-key>Available</vs-th>
          </template>

          <template v-for="(item, indextr) in table.data">
            <vs-tr
              :key="indextr"
              v-bind:style="(indextr%2==0)?'background-color:#f8f8f8':'background-color:#fff'"
            >
              <vs-td v-bind:rowspan="item.detail.length">{{ item.sku_code }}</vs-td>
              <vs-td v-bind:rowspan="item.detail.length">{{ item.item_name }}</vs-td>
              <vs-td>({{ item.detail[0].warehouse_code }}) {{ item.detail[0].warehouse_name}}</vs-td>
              <vs-td>{{item.detail[0].unit_uom }}</vs-td>
              <vs-td>{{ item.detail[0].on_hand_amt }}</vs-td>
              <vs-td>{{ item.detail[0].reserved_amt }}</vs-td>
              <vs-td>{{ item.detail[0].available_amt }}</vs-td>
            </vs-tr>
            <template v-for="(i, index) in item.detail">
              <vs-tr
                v-if="index>0"
                :key="item.sku_code+index"
                v-bind:style="(indextr%2==0)?'background-color:#f8f8f8':'background-color:#fff'"
              >
                <vs-td>({{ i.warehouse_code }}) {{ i.warehouse_name}}</vs-td>
                <vs-td>{{ i.unit_uom }}</vs-td>
                <vs-td>{{ i.on_hand_amt }}</vs-td>
                <vs-td>{{ i.reserved_amt }}</vs-td>
                <vs-td>{{ i.available_amt }}</vs-td>
              </vs-tr>
            </template>
          </template>
        </vs-table>
        <vs-pagination style="padding-top:5px" :total="table.totalPage" v-model="setPage" />
      </div>
    </div>
  </vx-card>
</template>
<script>
export default {
  data() {
    return {
      title: "Stock Area",
      baseUrl: "/api/v1/report/stock-view",
      table: this.tableState(),
      territoryArea: {
        selected: null,
        options: []
      },
      territoryAreaCode: "",
      territoryCode: ""
    };
  },
  methods: {
    tableState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "sku_code",
        sort: "asc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      if (active) {
        this.table.order = key;
        this.table.sort = active;
      } else {
        this.table.order = "sku_code";
        this.table.sort = "desc";
      }
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.detail = false;
      this.action = "";
    },
    handleCreate() {
      this.id = null;
      this.detail = true;
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            territory_area_code: this.territoryAreaCode
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.totalSearch < this.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    getAllDataTerritoryArea() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/territory-area", {
          params: {
            order: "code",
            type: "Sales"
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            this.territoryArea.options = resp.data.records;
            if (this.territoryArea.options.length > 0) {
              this.territoryArea.selected = this.territoryArea.options[0];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    handleExport(file) {
      // this.$vs.loading();
      this.$http
        .get("/api/v1/report/export", {
          params: {
            territory_area_code: this.territoryAreaCode,
            option: "Stock Area",
            file: file
          },
          responseType: "arraybuffer"
        })
        .then(resp => {
          // this.$vs.loading.close();
          // if (resp.status == "error") {
          //   this.$vs.notify({
          //     color: "danger",
          //     title: "Error",
          //     text: resp.message,
          //     position: "top-right",
          //     iconPack: "feather",
          //     icon: "icon-x-circle"
          //   });
          // }
          var fileURL = window.URL.createObjectURL(new Blob([resp]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          if (file == "excel") {
            fileLink.setAttribute(
              "download",
              this.territoryAreaCode + "_stock-area.xlsx"
            );
          } else {
            fileLink.setAttribute(
              "download",
              this.territoryAreaCode + "_stock-area.pdf"
            );
          }
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    }
  },
  mounted() {
    this.getAllDataTerritoryArea();
  },
  watch: {
    "territoryArea.selected": function(v) {
      if (v) {
        this.territoryAreaCode = v.code;
        this.getData();
      } else {
        this.table = this.tableState();
      }
    }
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        if (val != this.table.page) {
          this.handleChangePage(val);
        }
      }
    }
  }
};
</script>